import { useGiveawaySection } from 'root/src/client/web/giveaway/features/GiveawaySection/hooks/useGiveawaySection';
import { useEffect } from 'react';
import { useCreatorDataApi } from 'root/src/client/logic/api/hooks/useCreatorDataApi';
import { useCountdown } from 'root/src/client/v2/common/hooks/useCountdown';
import { GiveawayStatus, VoucherStatus } from '@daredrop-com/rewards-api-contract';
import { useGiveawayApi } from 'root/src/client/web/giveaway/hooks/useGiveawayApi';
import { formatISOTimeLeft } from 'root/src/shared/util/timeUtil';
export const useGiveawayTimer = () => {
    const creatorDataApi = useCreatorDataApi();
    const giveawayApi = useGiveawayApi();
    const { classes, giveaway } = useGiveawaySection();
    const { timeLeft, interval } = useCountdown((giveaway === null || giveaway === void 0 ? void 0 : giveaway.endDate) || new Date());
    useEffect(() => {
        return () => {
            void creatorDataApi.fetchGiveaways();
            void creatorDataApi.fetchScheduler();
            void giveawayApi.fetchVouchers(VoucherStatus.Available);
            interval.clear();
        };
    }, []);
    const isPickingWinner = (giveaway === null || giveaway === void 0 ? void 0 : giveaway.status) === GiveawayStatus.Active && timeLeft <= 0;
    return {
        classes,
        formattedISOTimeLeft: formatISOTimeLeft(timeLeft),
        isPickingWinner,
    };
};
