import moment from 'moment-mini';
export const daysToSeconds = (days) => days * 24 * 60 * 60;
export const secondsToDays = (seconds) => Math.ceil(seconds / 24 / 60 / 60);
export const secondsTimeFormatted = (seconds) => moment.utc(seconds * 1000).format('HH:mm:ss');
export const ceilToXMinutes = (time, minutes) => {
    return time.set('minutes', Math.ceil(time.minutes() / minutes) * minutes);
};
/**
 * Returns ISO time format ex. 04:10:52 (4 hours, 10 minutes, 52 seconds)
 */
export function formatISOTimeLeft(timeLeft, unit = 'ms') {
    const duration = moment.duration(timeLeft, unit);
    const hours = Math.floor(duration.asHours())
        .toString()
        .padStart(2, '0');
    const minutes = Math.floor(duration.minutes())
        .toString()
        .padStart(2, '0');
    const seconds = Math.floor(duration.seconds())
        .toString()
        .padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
}
