import { useIsAdmin } from 'root/src/client/v2/auth/hooks/useIsAdmin';
import { AuthGroup } from 'root/src/server/util/awsClients/authApiClient.types';
import { useGroups } from 'root/src/client/v2/auth/hooks/useGroups';
export function useIsBrand() {
    const { isAdmin } = useIsAdmin();
    const { groups } = useGroups();
    return {
        isBrand: groups.includes(AuthGroup.Brand) && !isAdmin,
        isBrandAdmin: groups.includes(AuthGroup.BrandAdmin) && !isAdmin,
        isBrandUser: groups.includes(AuthGroup.BrandUser) && !isAdmin,
        isEnterprise: groups.includes(AuthGroup.BrandEnterprise),
    };
}
