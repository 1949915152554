import { useState } from 'react';
import { useInterval } from 'root/src/client/v2/common/hooks/useInterval';
export function useCountdown(date) {
    const [timeLeft, setTimeLeft] = useState(() => {
        return new Date(date).getTime() - new Date().getTime();
    });
    const interval = useInterval(() => {
        setTimeLeft(getTimeLeft(date));
    }, 1000);
    return {
        timeLeft,
        interval,
    };
}
/**
 * Returns time left in milliseconds
 * @param date
 * @example
 * getTimeLeft('2021-08-01T00:00:00.000Z') // 1627776000000
 * @returns
 */
export function getTimeLeft(date) {
    return new Date(date).getTime() - new Date().getTime();
}
