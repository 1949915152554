import { useEffect, useState } from 'react';
export function useIsActiveTab() {
    const [isActiveTab, setIsActiveTab] = useState(() => {
        return 'document' in globalThis && !document.hidden;
    });
    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsActiveTab(!document.hidden);
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    return isActiveTab;
}
