import { useAppDispatch } from 'root/src/client/logic/helpers/useStore';
import { useUser } from 'root/src/client/v2/common/hooks/useUser';
import { useCreatorData } from 'root/src/client/logic/api/hooks/useCreatorData';
import auditFollowCreator from 'root/src/client/logic/creatorData/thunks/auditFollowCreator';
import displayModal from 'root/src/client/logic/modal/thunks/displayModal';
import { FOLLOW_CREATOR_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds';
import { useMemo } from 'react';
import { AuthGroup } from 'root/src/server/util/awsClients/authApiClient.types';
import { useModal } from 'root/src/client/v2/common/contexts/modal.context';
import { ActionModalContextProvider } from 'root/src/client/v2/_pages/ambassador-program/hooks/useActionModal';
import { AddCreatorsToProgramModal, } from 'root/src/client/v2/_pages/ambassador-program/components/AddCreatorsToProgramModal';
export const useCreatorDataActionButtons = () => {
    const dispatch = useAppDispatch();
    const user = useUser();
    const isAuthenticated = Boolean(user === null || user === void 0 ? void 0 : user.authentication);
    const creatorData = useCreatorData();
    const modal = useModal();
    const isTransformableToCreator = useMemo(() => {
        var _a;
        const authentication = user === null || user === void 0 ? void 0 : user.authentication;
        if (!isAuthenticated) {
            return false;
        }
        const isCreator = (_a = authentication === null || authentication === void 0 ? void 0 : authentication.groups) === null || _a === void 0 ? void 0 : _a.includes(AuthGroup.Creator);
        if (isCreator) {
            return false;
        }
        return (creatorData === null || creatorData === void 0 ? void 0 : creatorData.userId) === (authentication === null || authentication === void 0 ? void 0 : authentication.userId);
    }, [user, creatorData]);
    const onFollow = () => {
        if (!creatorData)
            return;
        if (isAuthenticated) {
            dispatch(auditFollowCreator());
            return;
        }
        dispatch(displayModal(FOLLOW_CREATOR_MODAL_MODULE_ID, { username: creatorData.mnemonicId }));
    };
    const onAddToProgram = () => {
        if (!creatorData) {
            return;
        }
        const creator = {
            userId: creatorData.userId,
            creatorDatas: [],
            programs: [],
            isOutsideCreator: false,
        };
        modal.show(<ActionModalContextProvider provider={{ selectedCreators: [creator] }}>
				<AddCreatorsToProgramModal />
			</ActionModalContextProvider>);
    };
    return {
        isTransformableToCreator,
        onFollow,
        onAddToProgram,
    };
};
